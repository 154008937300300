<template>
    <div>
      <header class="shadow-sm bg-green-200">
        <nav class="container mx-auto p-4 flex justify-between">
          <NuxtLink class="font-bold" to="/">Rumah Bandung</NuxtLink>
          <ul class="flex gap-4">
            <li><NuxtLink to="/">Home</NuxtLink></li>
            <li><NuxtLink class="btn" to="/property">Property</NuxtLink></li>
          </ul>
        </nav>
      </header>
      <div>
        <slot/>
      </div>
      <footer class="container mx-auto p-4 flex justify-between border-t-2">
        <ul class="flex gap-4">
            <li><NuxtLink to="/">Home</NuxtLink></li>
            <li><NuxtLink to="/property">Property</NuxtLink></li>
          </ul> 
      </footer>
      
    </div>
  </template>

  <style scoped>
  .router-link-exact-active {
    color: white

  }
</style>